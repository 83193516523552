





























































































































































































































import { Component, Vue } from 'vue-property-decorator'
import lsDialog from '@/components/ls-dialog.vue'
import {
    apiCalendarGetRule,
    apiCalendarSetRule,
    apiCalendarAddRule,
    apiCalendarEditRule,
    apiCalendarDelRule
} from '@/api/marketing/calendar'

@Component({
    components: {
        lsDialog
    }
})
export default class CalendarRuleEdit extends Vue {
    /** S Data **/

    // 规则信息
    list: any = {
        is_open: 1,
        daily: {
            integral_status: false,
            integral: ''
        },
        continuous: [],
        remark: ''
    }

    // 连续签到奖励信息
    signAdd = {
        days: '', //是	integer	连续签到天数
        integral_status: '0', //是	integer	赠送积分状态 0-关闭 1-开启
        integral: '' //是	integer	赠送积分数量
    }

    /** E Data **/

    /** S Methods **/
    // 添加连续签到奖励数据
    addRule() {
        apiCalendarAddRule({ ...this.signAdd }).then(res => {
            this.detail()
            // this.$message.success("添加成功!");
        })
    }

    setting(row: any) {
        this.signAdd.days = row.days
        this.signAdd.integral_status = row.integral_status
        this.signAdd.integral = row.integral
    }

    // 编辑连续签到奖励
    editRule(data: any) {
        apiCalendarEditRule({
            ...data
        }).then(res => {
            this.detail()
            // this.$message.success("修改成功!");
        })
    }

    // 删除连续签到天数
    delRule(data: any) {
        apiCalendarDelRule({ id: data.id }).then(res => {
            this.detail()
            // this.$message.success("删除成功!");
        })
    }

    // 设置签到规则
    onSubmit() {
        this.list.daily.integral_status = this.list.daily.integral_status
            ? 1
            : 0
        apiCalendarSetRule({ ...this.list })
            .then(() => {
                this.detail()
                // this.$message.success("修改成功!");
            })
            .catch(() => {
                // this.$message.error("数据获取失败!");
            })
    }

    // 详情
    detail() {
        apiCalendarGetRule({})
            .then(res => {
                this.list = res
            })
            .catch(() => {
                // this.$message.error("数据获取失败!");
            })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.detail()
    }
    /** E Life Cycle **/

    get status() {
        if (this.list.daily.integral_status == 1) {
            return true
        }
        return false
    }

    set status(event: any) {
        this.list.daily.integral_status = event
    }
}
